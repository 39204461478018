section[data-section='produccion'] {
  .item-prod {
    display: block;
    padding: 35px 0;

    li {
      font-size: 1.6rem;
      font-weight: 400 !important;
      font-family: $quickSand;
    }

    p {
      font-size: 1.6rem;
    }

    .slider-prod {
      img {
        display: block;
        margin: 0 auto;
        max-height: 370px;
      }
    }
  }
}