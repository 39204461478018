section[data-section='empresa'] {
  .logo-sobre {
    padding: 5px 0 20px 0;
    display: block;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}