.banner-top {
  background-size: cover;
  height: 158px;
  display: flex;
  justify-items: center;
  align-items: center;

  .container {
    h3 {
      letter-spacing: 4px;
      font-size: 2.8rem;
      font-weight: 300;
      font-family: $quickSand;
      text-transform: uppercase;
      color: white;
    }
  }
}