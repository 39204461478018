section[data-section="productos"] {
  .item-product {
    height: 250px;
    background-size: cover;
    border: 1px solid lightblue;
    position: relative;
    display: block;
    transition: all .3s ease;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(black, .5);
      opacity: 0;
      transition: all .3s ease;
    }

    &:hover {
      background-color: white;
      background-size: cover;

      &:after {
        opacity: 1;
      }
    }

    h3 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      text-align: center;
      letter-spacing: 2px;
      bottom: 10%;
      color: white;
      text-shadow: 0 0 4px black;
      text-transform: uppercase;
      font-family: $quickSand;
      z-index: 888;
    }

    p {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      color: white;
    }
  }
}