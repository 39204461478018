footer {
  background-color: black;
  height: 180px;
  position: relative;
  min-height: 100%;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 20px 0;
    height: auto;
  }

  .container {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    //border: 1px solid red;

    @include mobile {
      flex-direction: column;
    }

    .logo-footer {
      //border: 1px solid red;
      flex-basis: 75%;
      @include mobile {
        margin-bottom: 10px;
      }

      img {
        max-height: 50px;
      }
    }

    .data-footer {
      // border: 1px solid green;
      flex-basis: 25%;

      #google_translate_element {
        padding-top: 5px;
        overflow-y: hidden;
        height: 31px;
        select{
          padding: 1px;
          text-transform: uppercase;
          font-size: 1.2rem;
          background: transparent;
          &:focus,&:active{
            box-shadow: none;
          }
        }
        //border:1px solid red;
        //span{
        //  display: none;
        //}
      }

      ul {
        li {
          font-size: 1.1rem;
          letter-spacing: 1px;
          color: white;

          a {
            font-size: 1.1rem;
            color: white;
          }

          i {
            padding-right: 3px;
          }
        }
      }
    }
  }
}