body, html {
  background: white;
  font-size: 10px;

  a, ul, li, h1, h2, h3, h4 {
    text-decoration: none;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a, li, p {
    font-size: 1.4rem;
  }

  figure {
    line-height: 0;
  }
}

.section-page {
  padding: 30px 0;
}

.subtitle-page {
  //text-transform: uppercase;
  font-weight: 400;
  color: $color_page;
  font-family: $quickSand;
  margin-bottom: 10px;
  letter-spacing: 1px;

  &.upper {
    text-transform: uppercase;
  }
}

p {
  font-family: $quickSand;
  text-align: justify;
}

.button-page{
  background: transparent;
  color:$color_page;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $quickSand;
  border-radius: 0;
}