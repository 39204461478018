section[data-section='home'] {
  .slider {
    @include mobile{
      height: 130px;
    }
    figure {
      position: relative;

      figcaption {
        position: absolute;
        left: 50%;
        bottom: 18%;
        //border: 1px solid red;
        text-align: center;
        transform: translateX(-50%);

        h3, h4 {
          font-family: $quickSand;
          text-transform: uppercase;
          color: white;
          font-weight: 300;
          letter-spacing: 2px;
        }

        h3 {
          font-size: 4.5rem;
          @include mobile{
            font-size: 2.9rem;
          }
        }

        h4 {
          font-size: 2.7rem;
          @include mobile{
            font-size: 1.9rem;
          }
        }

      }
    }
  }

  .bloques {
    transform: translateY(-40px);

    .item {
      background: white;
      box-shadow: 0 0 2px grey;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 290px;
      width: 100%;
      @include mobile{
        margin-bottom: 15px;
      }

      figure {
        img {
          height: 123px;
        }
      }

      h3 {
        letter-spacing: 2px;
        font-size: 2.35rem;
        margin-top: 41px;
        color: $color_page;
        font-family: $quickSand;
        text-transform: uppercase;
      }
    }
  }

  .sobre-home {
    .logo {
      display: flex;
      align-content: center;
      align-items: center;
      //height: 246px;
      //border:1px solid red
    }

    .data {
      //border:1px solid red
    }
  }

  .divider {
    background: $color_page;
    height: 295px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    figure {
      //border: 1px solid red;
      text-align: center;
      padding-bottom: 20px;

      img {
        max-height: 65px;
        max-width: 100%;
      }
    }

    > .container {
      display: flex;
      flex-direction: column;

      .btn {
        border: 1px solid white;
        border-radius: 0;
        text-transform: uppercase;
        font-family: $quickSand;
        background: transparent;
        margin: 0 auto;
        display: block;
        width: 200px;
        transition: all 0.3s ease;

        &:hover {
          background: white;
          color:$color_page;
        }
      }
    }
  }
}