section[data-section='contacto'] {

  form {
    .form-control {
      margin-bottom: 10px;
      border-radius: 0;
    }

    label {
      display: none;
    }
  }

  .map {
    padding: 10px;
    background: white;
    box-shadow: 0 0 2px grey;
    margin-top: 10px;
  }
}