section[data-section='clientes'] {
  ul.list-clientes {
    display: flex;
    flex-wrap: wrap;
    @include mobile{
      flex-direction: column;
    }

    li {
      width: 25%;
      margin-bottom: 10px;
      display: block;
      //border: 1px solid red;
      padding: 10px;
      text-align: center;
      @include mobile{
        width: 100%;
        margin-bottom: 10px;
      }

      h4 {
        margin-top: 10px;
        font-size: 1.4rem;
        color: $color_page;
        text-transform: uppercase;
      }

      figure {
        height: 150px;
        display: block;

        img {
          height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}