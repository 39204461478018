header {
  background: $color_page;
  padding: 20px 0 0 0;
  transition: all .4s ease;

  @include mobile {
    .open-menu {
      color: white;
      display: block;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
  }

  &.header-sticky {
    background: $color_page;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;

    .logo-page {
      padding-bottom: 10px;

      img {
        max-height: 40px;
      }
    }

    .nav-page {
      a {
        font-size: 1rem;
      }
    }
  }

  .logo-page {
    text-align: center;
    padding-bottom: 15px;
    transition: all .4s ease;

    img {
      max-height: 50px;
      transition: all .4s ease;
    }
  }

  .nav-page {
    display: block;

    @include mobile {
      position: fixed;
      left: 0;
      top: -100%;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 999;
      transition: all .3s ease;

      .logo-page {
        padding-top: 20px;
      }
    }

    ul {
      display: flex;
      width: 100%;

      @include mobile {
        flex-direction: column;
        //border:1px solid red;
        height: auto;
      }

      li {
        flex: 1;
        position: relative;

        &.active {
          background: white;

          a {
            color: $color_page;

          }
        }

        ul.submenu {
          background: $color_page;
          display: block;
          padding: 0;
          position: absolute;
          left: 0;
          //border:1px solid red;
          //top: 30px;
          height: 0;
          overflow: hidden;
          transition: all .3s ease;
          @include mobile {
            position: relative;
            background: transparent;
          }

          li {
            //border: 1px solid grey;
          }

          //display: none;
        }

        a {
          font-size: 1.1rem;
          padding: 10px 0 10px 0;
          display: block;
          width: 100%;
          text-transform: uppercase;
          letter-spacing: 2px;
          text-align: center;
          color: white;
          transition: all .3s ease;

          @include mobile {
            color: $color_page !important;
          }

          &:hover {
            text-decoration: none;
            color: $color_page;
            background: white;
          }


        }
      }
    }
  }
}